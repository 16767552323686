import './scss/main.scss'

import $ from 'jquery';

import '@fortawesome/fontawesome-free/js/all.js'

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import 'owl.carousel';

import "bootstrap";

import "remodal";

var inst = $('[data-remodal-id=modal]').remodal();

$(".remodal-close").click(function(){
    var videoURL = $('#playerID').prop('src');
    videoURL = videoURL.replace("&autoplay=1", "");
    $('#playerID').prop('src','');
    $('#playerID').prop('src',videoURL);
});

$(".play button").click(function(){
    inst.open();
    return false;
});

/* $.ajax({
    method: "POST",
    url: "ajax/mail.php",
    data: { data },
    beforeSend : function(){
        
    }
}).done(function(resultado){
    alert(1)
}); */

/* var video = $("#myvideo")[0]; // id or class of your <video> tag
if (video.paused) {
    video.play();
}    */ 

$(".open-menu").click(function(){
    $(".menu-mobile").addClass("active");
});

$(".close-menu").click(function(){
    $(".menu-mobile").removeClass("active");
});

$(document).on('click', 'a.row-scroll', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top -200
    }, 1000);
});

$('.slider-home .owl-carousel').owlCarousel({
    loop:true,
    margin: 0,
    nav:false,
    dots: false,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }
    }
});

$('.slider-home .nav-home button.next').click(function() {
    $('.slider-home .owl-carousel').trigger('next.owl.carousel');
});

$('.slider-home .nav-home button.prev').click(function() {
    $('.slider-home .owl-carousel').trigger('prev.owl.carousel');
});

$('.slider-servicos .owl-carousel').owlCarousel({
    stagePadding: 50,
    loop:true,
    margin: 90,
    nav:false,
    dots: false,
    navText: ['<i class="fas fa-chevron-left"></i>','<i class="fas fa-chevron-right"></i>'],
    responsive:{
        0:{
            items:1
        },
        600:{
            items:4
        },
        1000:{
            items:4
        }
    }
});

$('.slider-servicos .nav-home button.next').click(function() {
    $('.slider-servicos .owl-carousel').trigger('next.owl.carousel');
});

$('.slider-servicos .nav-home button.prev').click(function() {
    $('.slider-servicos .owl-carousel').trigger('prev.owl.carousel');
});

$('.slider-sobre .owl-carousel').owlCarousel({
    loop:true,
    margin: 50,
    nav:true,
    dots: false,
    navText: ['<i class="fas fa-chevron-left"></i>','<i class="fas fa-chevron-right"></i>'],
    responsive:{
        0:{
            items:1
        },
        600:{
            items:4
        },
        1000:{
            items:4
        }
    }
});

$('footer .owl-carousel').owlCarousel({
    loop:true,
    margin: 50,
    nav:true,
    dots: false,
    navText: ['<i class="fas fa-chevron-left"></i>','<i class="fas fa-chevron-right"></i>'],
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }
    }
});